import api from "../httpClient.js";

export async function getListaBloqueio() {
  const response = await api.get("/relatorio-listabloqueio");
  return response.data.dados;
}

export async function getListaAtrasados() {
  const response = await api.get("/relatorio-listaatrasados");
  return response.data.dados;
}

export async function getListaVencidosDia() {
  const response = await api.get("/relatorio-listavencidosdia");
  return response.data.dados;
}
